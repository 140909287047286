<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    {{ $t('admin.configs.title') }}
                    <!--                    <v-spacer />
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />-->
                </v-card-title>

                <v-row>
                    <v-col
                        class="my-0 py-0"
                        cols="12"
                        md="6"
                        lg="3"
                        sm="6"
                    >
                        <!--                              -->
                        <v-switch
                            v-model="canRegister"
                            class="my-4 py-4 mx-8"
                            :label="$t('admin.configs.canRegister')"
                            @change="changeStateCanRegister($event)"
                        />
                    </v-col>
                </v-row>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'

// import { mapGetters } from 'vuex'

export default {
    name: "Configs",
    data: function () {
        return {
            canRegister: false,
            config: {
                users: {}
            }
        }
    },
    created() {
        this.loadConfig();
    },
    methods: {
        async loadConfig() {
            const configs = await axios
                .get('configs/1');

            const options = configs?.data?.data?.attributes?.options;

            if (options) {
                this.config.users = JSON.parse(options);
                this.canRegister = this.config.users.canRegister;
            }
        },
        async changeStateCanRegister(status) {
            this.config.users.canRegister = this.canRegister;

            var dataSubmit = {
                data: {
                    type: 'configs',
                    id: '1',
                    attributes: {
                        name: 'users',
                        options: JSON.stringify(this.config.users)
                    }
                }
            }

            try {
                /* const result = */await axios({
                    method: 'patch',
                    url: 'configs/1',
                    data: dataSubmit
                })

            /* if (result.status === 200 || result.status === 201 || result.status === 204) {

                const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
            } */
            } catch (error) {
                /* if (error.response.status === 422) {
              console.log('********** errors ********')
              console.log(error.response.data.errors)

                if (error.response?.data?.errors?.length) {

                }
            } */

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }
        }
    }
};
</script>
